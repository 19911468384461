<template>
  <el-timeline-item :timestamp='timestamp'
    placement='top'>
    <slot />
  </el-timeline-item>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'TimelineItem',
  props: [ 'log' ],
  computed: {
    objectLabel () {
      if (this.log.event_object === 'investment') {
        return 'investment'
      } else {
        return this.log.event_object
      }
    },
    timestamp () {
      let createdAt = this.log.eventType === 'comment' ? dayjs(this.log.created_at).format('HH:mm') : ''
      return `${dayjs(this.log.created_at).format('YYYY-MM-DD HH:mm:ss')}> ${this.log.username} ${this.log.event_type} ${this.objectLabel} ${createdAt}`
    },
  },
}
</script>
