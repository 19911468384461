<template>
  <div class='relative'>
    <v-select
      v-model='selectedContact'
      @input='updateSelectedContact'
      @search='searchContacts'
      label='contact_name'
      ref='contactSearch'
      :close-on-select='true'
      :multiple='isMultiple'
      :options='localContacts'
      :class='selectLayoutCSS'>
      <template v-slot:option='option'>
        <div class='px-2 py-2 justify-between items-center'>
          <div class='font-bold'>{{option.contact_name}}</div>
          <div v-if='contactEntityName(option.entity_name)' class='opacity-75 px-5 py-1' style='color:blue'>
            {{contactEntityName(option.entity_name)}}
          </div>
          <div v-if='contactPhoneNumber(option.phone_number)' class='opacity-75 px-5 py-1' style='color:blue'>
            {{contactPhoneNumber(option.phone_number)}}
          </div>
          <div v-if='contactEmail(option.email)' class='opacity-75 px-5 py-1' style='color:blue'>
            {{contactEmail(option.email)}}
          </div>
        </div>
      </template>
      <template v-slot:no-options>
        <div class='text-gray-500 py-3'>찾으시려는 이름을 입력하세요.</div>
      </template>
      <template v-slot:spinner>
        <div v-if='loading'>
          <i class='el-icon-loading'></i>
        </div>
      </template>
      <template #list-footer>
        <div v-if='searchText'
          @click='createNewContact'
          class='px-2 py-3 border-t hover:bg-gray-200 text-xs cursor-pointer'>
          {{searchText}}
          <span class='inline-block opacity-75 ml-2'>신규추가</span>
        </div>
      </template>
    </v-select>
    <portal to='modals'>
      <contact-edit
        v-if='showEditContact'
        :display-in-modal='true'
        @updated-contact='doneCreatingNew' />
    </portal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import debounce                               from 'debounce'
import EventBus                               from '@/utils/event-bus'

export default {
  name: 'ContactSearch',
  components: {
    ContactEdit: () => import('@/components/contacts/ContactEdit.vue'),
  },
  props: [
    'contactId',
    'contactName',
    'isMultiple',
    'customCSSClasses',
  ],
  data () {
    return {
      selectedContact: {
        id: this.contactId,
        contact_name: this.contactName,
      },
      localContacts: [{
        id: this.contactId,
        contact_name: this.contactName,
      }],
      loading: false,
      minSearchLength: 0,
      showEditContact: false,
    }
  },
  computed: {
    ...mapState('contacts', [
      'contactsBySearch',
    ]),
    searchText () {
      return this.$refs.contactSearch.search
    },
    selectLayoutCSS () {
      return (this.customCSSClasses) ? this.customCSSClasses : 'w-full'
    },
  },
  methods: {
    ...mapActions('contacts', [
      'createContact',
      'getContactsByName',
      'editContact',
      'getContactCustomFields',
    ]),
    ...mapMutations('contacts', [
      'setEditingContactName'
    ]),
    searchContacts () {
      if (this.searchText && this.searchText.length > 0) {
        this.loading = true
        this.getContactsByName(this.searchText).then(resp => {
          this.localContacts = resp
          this.loading = false
        })
      }
    },
    updateSelectedContact () {
      if (this.selectedContact && this.selectedContact.id > 0) {
        this.$emit('update-selected-contact', this.selectedContact)
      }
    },
    contactEntityName (entityName) {
      return entityName ? `의뢰인: ${entityName}` : ''
    },
    contactEmail (email) {
      return email ? `메일주소: ${email}` : ''
    },
    contactPhoneNumber (phoneNumber) {
      return (phoneNumber) ? `연락처: ${phoneNumber}` : ''
    },
    isAddContactOption (contactId) {
      return (contactId <= 0)
    },
    createNewContact () {
      this.showEditContact = true
      this.setEditingContactName(this.searchText)
      this.getContactCustomFields(0)
      this.$store.dispatch('modalOpen')
    },
    doneCreatingNew (createdContact) {
      if (createdContact) { // create contact
        this.localContacts = []
        this.localContacts.push(createdContact)
        this.selectedContact = createdContact
        this.updateSelectedContact()
      } else { // cancel edit
        this.localContacts = []
        this.selectedContact = null
        this.showEditContact = false
      }
      this.$store.dispatch('modalClose')
    },
  },
  created () {
    this.searchContacts = debounce(this.searchContacts, 300)
  },
  mounted () {
    EventBus.$on('modalClose', () => {
      this.showEditContact = false
    })
  },
  beforeDestroy () {
    EventBus.$off('modalClose')
  }
}
</script>
