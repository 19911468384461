import base from './base'

const getPositions = (filter) => base
  .get('/positions/', { params: filter }).then(resp => resp.data)

const getPositionClosePrices = (createdOn) => base
  .get(`/positions/show_by_date/?created_on=${createdOn}`).then(resp => resp.data)

const putPositionClosePrices = (closePriceParams) => base
  .patch('/positions/put_close_price/', {close_prices: closePriceParams}).then(resp => resp.data)

const getPosition = (positionId) => base
  .get(`/positions/${positionId}`).then(resp => resp.data)

const getPositionsByDateCode = (filter) => base
  .get('/positions/show_by_code', { params: filter }).then(resp => resp.data)

const getPositionsByDateInvestment = (filter) => base
  .get('/positions/show_by_investment', { params: filter }).then(resp => resp.data)

const getPeriodPositions = (filters) => base
  .get('/positions/period_positions', { params: filters }).then(resp => resp.data)

const getIndirectSummary = (filters) => base
  .get('/positions/indirect_summary', { params: filters }).then(resp => resp.data)

const getInvestmentFund = (filters) => base
  .get('/positions/investment_fund', { params: filters }).then(resp => resp.data)

export default {
  getPositions: getPositions,
  getPositionClosePrices: getPositionClosePrices,
  putPositionClosePrices: putPositionClosePrices,
  getPosition: getPosition,
  getPositionsByDateCode: getPositionsByDateCode,
  getPositionsByDateInvestment: getPositionsByDateInvestment,
  getPeriodPositions: getPeriodPositions,
  getIndirectSummary: getIndirectSummary,
  getInvestmentFund: getInvestmentFund,
}
