<template>
  <div>
    <div v-for='value in showFieldsByTemplate'
      :key='`customField-${customField.id}-value-${value.custom_field_value_id}-${value.recurring_index}`'
      :class='recurringStyleLayout'>
      <custom-field-value
        class='leading-10 flex-grow'
        :field='customFieldFromValue(value)'
        :is-view-mode='isViewMode'>
      </custom-field-value>
      <button v-if='isEditMode'
        @click='removeRecurringField(value)'>
        <x-circle-icon class='inline-bock text-red-900 opacity-75' />
      </button>
    </div>
    <div v-for='addedValue in newAddedFields'
      :key='`customField-${customField.id}-add-value-${addedValue.recurring_index}`'
      :class='recurringStyleLayout'>
      <custom-field-value
        class='leading-10 flex-grow'
        :field='addedValue'
        :is-view-mode='isViewMode'>
      </custom-field-value>
      <button v-if='isEditMode'
        @click='removeAddedRecurringField(addedValue)'>
        <x-circle-icon class='inline-bock text-red-900 opacity-75' />
      </button>
    </div>
    <button v-if='isEditMode'
      @click='addValue'
      class='mb-4 px-4 rounded text-sm border border-transparent hover:border-blue-300 hover:shadow'>
      <plus-circle-icon class='inline-block text-gray-700' style='margin-top: -4px' />
      추가
      <span class='text-gray-500 uppercase'>({{customField.custom_field_name}})</span>
    </button>
  </div>

</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { XCircleIcon, PlusCircleIcon } from '@vue-hero-icons/outline'
import cloneDeep                       from 'lodash/cloneDeep'
import maxBy                           from 'lodash/maxBy'
import CustomFieldValue from '@/components/custom_fields/CustomFieldValue.vue'
export default {
  name: 'CustomFieldValueRecurring',
  components: {
    CustomFieldValue,
    PlusCircleIcon,
    XCircleIcon,
  },
  props: [
    'customField',
    'isViewMode',
  ],
  data () {
    return {
      newAddedFields: [],
    }
  },
  watch: {
    drilldownEditedFields: {
      handler: function (newVal) {
        if (newVal.length === 0) {
          // when finished editing clear added fields
          this.resetAddedFields()
        }
      }
    }
  },
  computed: {
    ...mapState('investments', [
      'drilldownEditedFields',
    ]),
    ...mapGetters('investments', [
      'sidepanelShowFieldsByTemplateId',
    ]),
    ...mapGetters('contacts', [
      'contactFieldsByTemplateId',
    ]),
    fieldsByTemplate () {
      if (this.customField.template_type === 'investment') {
        return this.sidepanelShowFieldsByTemplateId[this.customField.id] || []
      } else if (this.customField.template_type === 'contact') {
        return this.contactFieldsByTemplateId[this.customField.id] || []
      } else {
        return []
      }
    },
    showFieldsByTemplate () {
      if (this.customField.recurring) {
        if (this.customField.field_type === 'file') {
          // sort by date
          return this.fieldsByTemplate.filter(field => field.value !== null)
                                      .sort((a, b) => new Date(a.metadata.file_date) - new Date(b.metadata.file_date))
        } else {
          // no sorting
          return this.fieldsByTemplate.filter(field => field.value !== null)
        }
      } else {
        // always show default
        return this.fieldsByTemplate
      }
    },
    isEditMode () {
      return !this.isViewMode
    },
    nextRecurringIndex () {
      let maxByRecurringIndex = maxBy(this.newAddedFields, 'recurring_index')
      if (maxByRecurringIndex) {
        return maxByRecurringIndex.recurring_index + 1
      } else {
        let latestRecurringIndex = maxBy(this.fieldsByTemplate, 'recurring_index')
        if (latestRecurringIndex) {
          return latestRecurringIndex.recurring_index + 1
        } else {
          return 0
        }
      }
    },
    recurringStyleLayout () {
      return (this.isEditMode) ? 'flex flex-row justify-start mb-2' : ''
    },
  },
  methods: {
    ...mapActions('investments', [
      'editDrilldownFieldValue',
    ]),
    customFieldFromValue (value) {
      let field = cloneDeep(value)
      return field
    },
    addValue () {
      let field = cloneDeep(this.customField)
      field['display_value'] = null
      field['value'] = null
      field['value_json'] = []
      field['custom_field_value_id'] = null
      field['file_url'] = null
      field['recurring_index'] = this.nextRecurringIndex // this is not the same as the index on the array
      this.newAddedFields.push(field)
    },
    removeRecurringField (field) {
      let removeField = cloneDeep(field)
      removeField.value = null
      this.editDrilldownFieldValue(removeField)
    },
    removeAddedRecurringField (field) {
      let removeField = cloneDeep(field)
      removeField.value = null
      this.editDrilldownFieldValue(removeField)
      this.reomveFromNewAdded(field.recurring_index)
    },
    reomveFromNewAdded (recurringIndex) {
      let index = this.newAddedFields.findIndex(addedField => addedField.recurring_index === recurringIndex)
      if (index !== -1) {
        this.newAddedFields.splice(index, 1)
      }
    },
    resetAddedFields () {
      this.newAddedFields = []
    },
  }
}
</script>
