<template>
  <div class='py-12'>
    <table class='text-base' style='position: relative; margin-left: 20px; width: 830px;'>
      <thead>
        <tr class='border-b'>
          <td v-if='!isMain' class='px-2 py-2 text-right border-r'>투자ID</td>
          <td v-if='!isMain' class='px-2 py-2 text-left border-l border-r'>투자일자</td>
          <td v-if='!isMain' class='px-2 py-2 text-left border-l border-r'>투자명</td>
          <td v-if='isMain' class='px-2 py-2 text-left border-l border-r'>투자전략</td>
          <td v-if='isMain' class='px-2 py-2 text-right border-r'>투자건수</td>
          <td class='px-2 py-2 text-right border-r'>(억원)투자금액</td>
          <td class='px-2 py-2 text-right border-r'>(억원)회수금액</td>
          <td class='px-2 py-2 text-right border-r'>(억원)회수원금</td>
          <td class='px-2 py-2 text-right border-r'>(억원)회수이익</td>
          <td class='px-2 py-2 text-right border-r'>수익률</td>
          <td class='px-2 py-2 text-right border-r'>IRR</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for='(portfolio, index) in portfolios'
          :key='`portfolio-${index}`'
          @click='clickCollection(portfolio)'
          class='bg-white hover:bg-blue-100 border-b' >
          <td v-if='!isMain' class='px-2 py-2 text-right border-l border-r'>{{ portfolio.tms_investment_id }}</td>
          <td v-if='!isMain' class='px-2 py-2 text-left border-r'>{{ portfolio.investment_date }}</td>
          <td v-if='!isMain' class='px-2 py-2 text-left border-l border-r'>{{ portfolio.name }}</td>
          <td v-if='isMain' class='px-2 py-2 text-left border-l border-r'>{{ portfolio.strategy }}</td>
          <td v-if='isMain' class='px-2 py-2 text-right border-r'>{{ portfolio.investment_count }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(portfolio.investment_value) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(portfolio.collection_value) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(portfolio.original_value) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(portfolio.profit) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ collectionRate(portfolio) }}%</td>
          <td class='px-2 py-2 text-right border-r'>{{ portfolio.xirr }}%</td>
        </tr>
      </tbody>
      <tfoot>
        <tr class='border-b'>
          <td class='bg-gray-100 px-2 py-2 text-left border-l border-r'>Total</td>
          <td v-if='!isMain' class='bg-gray-100 px-2 py-2 border-r'></td>
          <td class='bg-gray-100 px-2 py-2 text-right border-r'>{{ sumValue('investment_count') }}</td>
          <td class='bg-gray-100 px-2 py-2 text-right border-r'>{{ toNumber(sumValue('investment_value')) }}</td>
          <td class='bg-gray-100 px-2 py-2 text-right border-r'>{{ toNumber(sumValue('collection_value')) }}</td>
          <td class='bg-gray-100 px-2 py-2 text-right border-r'>{{ toNumber(sumValue('original_value')) }}</td>
          <td class='bg-gray-100 px-2 py-2 text-right border-r'>{{ toNumber(sumValue('profit')) }}</td>
          <td class='bg-gray-100 px-2 py-2 text-right border-r'>{{ collectionProfitRate() }}% </td>
          <td class='bg-gray-100 px-2 py-2 text-right border-r'>{{ averageXirr() }}% </td>
        </tr>
        <tr>
          <td colspan='3'>
            <button class='px-4 font-bold text-right border rounded-md border-transparent hover:border-gray-500'
              @click='copyToClipboard'>Copy To Clipboard</button>
          </td>
        </tr>
      </tfoot>
    </table>

    <portal to='modals'>
      <portfolio-details v-if='sideOpen'
        :display-in-modal='true'
        class='px-16' />
    </portal>

  </div>
</template>

<script>
import numbro                      from 'numbro'
import { mapActions, mapState }    from 'vuex'
import PortfolioDetails            from '@/views/portfolio/PortfolioDetails.vue'
import PortfoliosApi               from '@/api/v1/portfolios'


export default {
  name: 'CollectionReport',
  components: {
    PortfolioDetails,
  },
  props: [
    'portfolios',
    'isMain',
  ],
  data () {
    return {
      sideOpen: false,
    }
  },
  computed: {
    ...mapState('dashboardViews', [
      'currentDashboardView',
      'dashboardViews',
    ]),
  },
  methods: {
    ...mapActions('portfolios', [
      'portfolioDetailsOpen',
    ]),
    ...mapActions('investments', [
      'getInvestmentData',
    ]),
    ...mapActions('dashboardViews', [
      'getDashboardViews',
      'selectDashboardView',
    ]),
    clickCollection (portfolio) {
      if (this.isMain) {
        this.$emit('click-collection', portfolio)
      } else {
        PortfoliosApi.getGroupPortfolios( {entity_id: portfolio.entity_id} ).then(resp => {
          if (resp) {
            this.openPortfolioDetail (resp[0])
          }
        })
      }
    },
    openPortfolioDetail (portfolio) {
      this.setListEditView ()
      this.getInvestmentData(portfolio.investment_id).then ( () => {
        this.portfolioDetailsOpen(portfolio)
        this.sideOpen = true
      })
    },
    setListEditView () {
      if (this.currentDashboardView.view_layout === 'List Edit' && this.currentDashboardView.dashboard_view_name.substring(0,4) === 'Deal') {
        return
      }

      if (this.dashboardViews.length === 0) {
        this.getDashboardViews ()
      }

      for (var ii = 0; ii < this.dashboardViews.length; ii++) {
        if (this.dashboardViews[ii].view_layout === 'List Edit' && this.dashboardViews[ii].dashboard_view_name.substring(0,4) === 'Deal') {
          this.selectDashboardView(this.dashboardViews[ii])
          break
        }
      }
      return
    },
    toNumber (number) {
      if (number) {
        return numbro(parseInt(Math.round(number / 100000000))).format({ thousandSeparated: true })
      } else {
        return 0
      }
    },
    toNumberN (number) {
      if (number) {
        return numbro(Math.round(number)).format({ thousandSeparated: true })
      } else {
        return 0
      }
    },
    sumValue (type) {
      return this.portfolios.reduce(function(a, portfolio){ return a + parseInt(portfolio[type]) }, 0)
    },
    profitRate (portfolio) {
      if (portfolio.investment_value === 0 || portfolio.current_value === 0)
        return 0

      return ((portfolio.current_value - portfolio.investment_value) * 100 / portfolio.investment_value).toFixed(2)
    },
    collectionRate (portfolio) {
      if (parseInt(portfolio.collection_value) === 0 || parseInt(portfolio.original_value) === 0)
        return 0

      return ((portfolio.collection_value - portfolio.original_value) * 100 / portfolio.original_value).toFixed(2)
    },
    collectionProfitRate () {
      let collectionValue = this.sumValue('collection_value')
      let originalValue = this.sumValue('original_value')

      if (parseInt(collectionValue) === 0 || parseInt(originalValue) === 0) {
        return 0
      }

      return ((collectionValue - originalValue) * 100 / originalValue).toFixed(2)
    },
    totalProfitRate () {
      let investmentValue = this.sumValue('investment_value')
      let currentValue = this.sumValue('current_value')

      if (parseInt(investmentValue) === 0 || parseInt(currentValue) === 0) {
        return 0
      }

      return ((currentValue - investmentValue) * 100 / investmentValue).toFixed(2)
    },
    averageXirr () {
      let totalXirrValue = 0
      let totalXirr = 0
      this.portfolios.forEach( portfolio => {
        totalXirrValue += parseFloat(portfolio.xirr_value)
        totalXirr += parseFloat(portfolio.xirr_value) * parseFloat(portfolio.xirr)
      })
      if (totalXirrValue === 0) {
        return 0
      } else {
        return (totalXirr / totalXirrValue).toFixed(2)
      }
    },
    copyToClipboard () {
      let string = ''

      string = this.tableHeaderString ()
      this.portfolios.forEach(portfolio => {
        string += this.convertDataToString(portfolio)
      })
      string += this.tableFooterString ()
      this.$copyText(string)
    },
    tableHeaderString () {
      let string = ''

      if (this.isMain) {
        string += '투자전략' + '\t'
        string += '투자건수' + '\t'
      } else {
        string += '투자ID' + '\t'
        string += '투자일자' + '\t'
        string += '투자전략' + '\t'
      }
      string += '투자금액(억)' + '\t'
      string += '회수금액(억)' + '\t'
      string += '회수원금(억)' + '\t'
      string += '회수이익(억)' + '\t'
      string += '수익률(%))' + '\t'
      string += 'IRR' + '\t'
      string += '\n'

      return string
    },
    convertDataToString(portfolio) {
      let string = ''

      if (this.isMain) {
        string += portfolio.strategy + '\t'
        string += portfolio.investment_count + '\t'
      } else {
        string += portfolio.tms_investment_id + '\t'
        string += portfolio.investment_date + '\t'
        string += portfolio.name + '\t'
      }
      string += this.toNumberN(portfolio.investment_value) + '\t'
      string += this.toNumberN(portfolio.collection_value) + '\t'
      string += this.toNumberN(portfolio.original_value) + '\t'
      string += this.toNumberN(portfolio.profit) + '\t'
      string += this.collectionRate(portfolio) + '\t'
      string += portfolio.xirr + '\t'
      string += '\n'

      return string
    },
    tableFooterString () {
      let string = ''

      string += '합계' + '\t'
      if (!this.isMain)
        string += '' + '\t'
      string += this.sumValue('investment_count') + '\t'
      string += this.toNumberN(this.sumValue('investment_value')) + '\t'
      string += this.toNumberN(this.sumValue('collection_value')) + '\t'
      string += this.toNumberN(this.sumValue('original_value')) + '\t'
      string += this.toNumberN(this.sumValue('profit')) + '\t'
      string += this.collectionProfitRate() + '\t'
      string += this.averageXirr() + '\t'
      string += '\n'

      return string
    },
  },
}
</script>

<style lang='scss' scoped>
  th {
    @apply py-3 px-2 uppercase tracking-wide;
    background-color: #FFF;
    white-space: nowrap;
    z-index: 2;
  }
  td {
    @apply py-3 pl-2 pr-6;
    white-space: nowrap;
  }
</style>
