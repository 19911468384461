<template>
  <div class='py-0 lg:py-4'>
    <div class='flex flex-row justify-start items-center gap-x-2 mt-8 mb-1'>
      <h2 class='text-base lg:text-xl font-medium text-gray-800'>펀드별 보유현황</h2>
    </div>
    <div v-if='hasRows'>
      <table class='text-xs lg:text-base min-w-full'>
        <thead>
          <th class='bg-gray-100 font-gray-700 whitespace-no-wrap px-1 lg:px-2 py-2 lg:py-3 text-right border-r border-b border-t'>펀드명</th>
          <th class='bg-gray-100 font-gray-700 whitespace-no-wrap px-1 lg:px-2 py-2 lg:py-3 text-right border-r border-b border-t'>
            <div class='flex flex-row lg:flex-row justify-end gap-x-1'>
              <span class='whitespace-no-wrap'>보유수량</span>
              <span class='font-thin lg:font-normal'>(주)</span>
            </div>
          </th>
          <th class='bg-gray-100 font-gray-700 whitespace-no-wrap px-1 lg:px-2 py-2 lg:py-3 text-right border-r border-b border-t'>
            <div class='flex flex-row lg:flex-row justify-end gap-x-1'>
              <span class='whitespace-no-wrap'>보유원금</span>
              <span class='font-thin lg:font-normal'>(백만원)</span>
            </div>
          </th>
          <th class='bg-gray-100 font-gray-700 whitespace-no-wrap px-1 lg:px-2 py-2 lg:py-3 text-right border-r border-b border-t'>
            <div class='flex flex-row lg:flex-row justify-end gap-x-1'>
              <span class='whitespace-no-wrap'>단가</span>
              <span class='font-thin lg:font-normal'>(원)</span>
            </div>
          </th>
          <th class='bg-gray-100 font-gray-700 whitespace-no-wrap px-1 lg:px-2 py-2 lg:py-3 text-right border-r border-b border-t'>
            <div class='flex flex-row lg:flex-row justify-end gap-x-1'>
              <span class='whitespace-no-wrap'>투자밸류</span>
              <span class='font-thin lg:font-normal'>(백만원)</span>
            </div>
          </th>
          <th class='bg-gray-100 font-gray-700 whitespace-no-wrap px-1 lg:px-2 py-2 lg:py-3 text-right border-r border-b border-t'>
            <div class='flex flex-row lg:flex-row justify-end gap-x-1'>
              <span class='whitespace-no-wrap'>투자원금</span>
              <span class='font-thin lg:font-normal'>(백만원)</span>
            </div>
          </th>
          <th class='bg-gray-100 font-gray-700 whitespace-no-wrap px-1 lg:px-2 py-2 lg:py-3 text-right border-r border-b border-t'>
            <div class='flex flex-row lg:flex-row justify-end gap-x-1'>
              <span class='whitespace-no-wrap'>펀드내비중</span>
            </div>
          </th>
        </thead>
        <tbody>
          <tr v-for='portfolio in portfolios'
            @click='selectPortfolio(portfolio)'
            :class='highlightRow(portfolio)'
            :key='`portfolio-${portfolio.short_name}`'>
            <td class='p-1 lg:px-2 lg:py-2 text-left border-r border-l'>{{ portfolio.short_name }}</td>
            <td class='p-1 lg:px-2 lg:py-2 text-right border-r'>{{ toNumber(portfolio.holding_quantity) }}</td>
            <td class='p-1 lg:px-2 lg:py-2 text-right border-r'>{{ toNumber(portfolio.holding_value) }}</td>
            <td class='p-1 lg:px-2 lg:py-2 text-right border-r'>{{ toNumber(portfolio.holding_price) }}</td>
            <td class='p-1 lg:px-2 lg:py-2 text-right border-r'>{{ toNumber(portfolio.market_investment_value) }}</td>
            <td class='p-1 lg:px-2 lg:py-2 text-right border-r'>{{ toNumber(portfolio.investment_value) }}</td>
            <td class='p-1 lg:px-2 lg:py-2 text-right border-r'>{{ portfolio.investment_ratio }}</td>
          </tr>
        </tbody>
        <tfoot v-if='showTotalRow'>
          <tr>
            <td class='font-semibold p-1 lg:px-2 lg:py-2 text-left border-b border-l border-r'>합계</td>
            <td class='font-semibold p-1 lg:px-2 lg:py-2 text-right border-b border-r'>{{ toNumber(parseInt(sumHoldingQuantity)) }}</td>
            <td class='font-semibold p-1 lg:px-2 lg:py-2 text-right border-b border-r'>{{ toNumber(parseInt(sumHoldingValue)) }}</td>
            <td class='font-semibold p-1 lg:px-2 lg:py-2 text-right border-b border-r'>{{ toNumber(parseInt(averageHolding)) }}</td>
            <td class='font-semibold p-1 lg:px-2 lg:py-2 text-right border-b border-r'>{{ marketAverageValue() }}</td>
            <td class='font-semibold p-1 lg:px-2 lg:py-2 text-right border-b border-r'>{{ toNumber(parseFloat(sumInvestmentValue)) }}</td>
            <td class='font-semibold p-1 lg:px-2 lg:py-2 text-right border-b border-r'>{{ sumInvestmentRatio.toFixed(2) }}</td>
          </tr>
        </tfoot>
      </table>
      <button
        class='mt-2 px-2 lg:px-4 py-1 lg:py-2 font-bold uppercase rounded-md border border-gray-300 hover:border-gray-500 text-xs lg:text-sm'
        @click='copyToClipboard'>
        Copy To Clipboard
      </button>
    </div>
    <div v-else
      class='bg-gray-100 rounded-lg text-center p-8 lg:p-12 mr-2 uppercase text-sm font-semibold'>
      No Funds Data.
    </div>
  </div>
</template>

<script>
import numbro                 from 'numbro'
import { mapGetters }         from 'vuex'
import PositionsApi           from '@/api/v1/positions'

export default {
  name: 'PortfolioFunds',
  data () {
    return {
      portfolios: [],
      selectedPortfolio: {},
      sumInvestmentValue: 0.0,
      sumHoldingValue: 0,
      sumHoldingRawValue: 0,
      totMarketValue: 0,
      marketValue: 0,
      sumHoldingQuantity: 0,
      sumInvestmentRatio: 0,
      averageHolding: 0,
    }
  },
  watch: {
    'drilldownInvestmentId': {
      handler: function (newId) {
        if (newId > 0) {
          this.getPortfolioFunds()
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('investments', [
      'drilldownInvestmentId',
    ]),
    showTotalRow () {
      return this.portfolios.length > 1
    },
    hasRows () {
      return this.portfolios.length > 0
    },
  },
  methods: {
    marketAverageValue () {
      if (this.marketValue <= 0 || this.totMarketValue <= 0)
        return 0
      else
        return this.toNumber(this.totMarketValue / this.marketValue)
    },
    highlightRow (portfolio) {
      return portfolio.short_name === this.selectedPortfolio.short_name ? 'bg-blue-100 font-semibold border-b' : 'border-b'
    },
    selectPortfolio (portfolio) {
      if (portfolio) {
        this.selectedPortfolio = portfolio
      }
    },
    toNumber (number) {
      if (number) {
        return numbro(parseInt(number)).format({ thousandSeparated: true })
      } else {
        return 0
      }
    },
    getPortfolioFunds () {
      PositionsApi.getInvestmentFund({ investment_id: this.drilldownInvestmentId }).then(resp => {
        this.portfolios = resp
        this.sumInvestmentValue = 0
        this.sumHoldingValue = 0
        this.sumHoldingRawValue = 0
        this.totMarketValue = 0
        this.marketValue = 0
        this.sumHoldingQuantity = 0
        this.sumInvestmentRatio = 0

        this.portfolios.forEach(portfolio => {
          this.sumInvestmentValue += portfolio.investment_raw_value
          this.sumHoldingValue += portfolio.holding_value
          this.sumHoldingRawValue += portfolio.holding_raw_value
          this.marketInvestmentValue += portfolio.market_investment_value
          this.totMarketValue += portfolio.market_investment_value * portfolio.investment_value
          if (portfolio.market_investment_value > 0)
            this.marketValue += portfolio.investment_value
          this.sumHoldingQuantity += portfolio.holding_quantity
          this.sumInvestmentRatio += portfolio.investment_ratio
        })
        this.sumInvestmentValue = this.sumInvestmentValue / 1000000
        this.averageHolding = (this.sumHoldingQuantity === 0 ? 0 : this.sumHoldingRawValue / this.sumHoldingQuantity).toFixed(0)
      })
    },
    copyToClipboard () {
      let string = ''
      string = this.tableHeaderString ()
      this.portfolios.forEach(portfolio => {
        string += this.convertDataToString(portfolio)
      })
      string += this.tableFooterString ()
      this.$copyText(string)
    },
    tableHeaderString () {
      let string = ''
      string += '펀드명' + '\t'
      string += '보유수량(주)' + '\t'
      string += '보유원금(백만원)' + '\t'
      string += '단가(원)' + '\t'
      string += '투자밸류(백만원)' + '\t'
      string += '투자원금(백만원)' + '\t'
      string += '펀드내비중' + '\t'
      string += '\n'

      return string
    },
    convertDataToString(portfolio) {
      let string = ''
      string += portfolio.short_name + '\t'
      string += this.toNumber(portfolio.holding_quantity) + '\t'
      string += this.toNumber(portfolio.holding_value) + '\t'
      string += this.toNumber(portfolio.holding_price) + '\t'
      string += this.toNumber(portfolio.market_investment_value) + '\t'
      string += this.toNumber(portfolio.investment_value) + '\t'
      string += portfolio.investment_ratio + '\t'
      string += '\n'

      return string
    },
    tableFooterString () {
      let string = ''
      string += '합계' + '\t'
      string += this.toNumber(this.sumHoldingQuantity) + '\t'
      string += this.toNumber(this.sumHoldingValue) + '\t'
      string += this.toNumber(this.averageHolding) + '\t'
      string += this.marketAverageValue() + '\t'
      string += this.toNumber(this.sumInvestmentValue) + '\t'
      string += this.toNumber(this.sumInvestmentRatio) + '\t'
      string += '\n'

      return string
    },
  },
}
</script>
