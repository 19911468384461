<template>
  <div class='lg:py-4'>
    <table class='mt-4 text-xs lg:text-base'>
      <thead>
        <th class='bg-gray-100 font-gray-700 whitespace-no-wrap px-1 lg:px-2 py-2 lg:py-3 text-left border-r border-b border-t border-l'>투자일자</th>
        <th class='bg-gray-100 font-gray-700 whitespace-no-wrap px-1 lg:px-2 py-2 lg:py-3 text-left border-r border-b border-t'>자산명</th>
        <th class='bg-gray-100 font-gray-700 whitespace-no-wrap px-1 lg:px-2 py-2 lg:py-3 text-right border-r border-b border-t'>투자단가(원)</th>
        <th class='bg-gray-100 font-gray-700 whitespace-no-wrap px-1 lg:px-2 py-2 lg:py-3 text-right border-r border-b border-t'>투자수량(주)</th>
        <th class='bg-gray-100 font-gray-700 whitespace-no-wrap px-1 lg:px-2 py-2 lg:py-3 text-right border-r border-b border-t'>투자원금(백만원)</th>
        <th class='bg-gray-100 font-gray-700 whitespace-no-wrap px-1 lg:px-2 py-2 lg:py-3 text-right border-r border-b border-t'>투자밸류(백만원)</th>
      </thead>
      <tbody>
        <tr v-for='(portfolio, index) in portfolios'
          @click='selectPortfolio(portfolio)'
          :class='highlightRow(portfolio)'
          :key='`portfolio-${index}`'
          class='font-normal'>
          <td class='px-1 py-1 lg:px-2 lg:py-2 text-left  border-r border-l whitespace-no-wrap'>{{ portfolio.investment_date }}</td>
          <td class='px-1 py-1 lg:px-2 lg:py-2 text-left  border-r whitespace-no-wrap'>
            {{ portfolio.name }}
            <span :class='oldOrNewTagStyle(portfolio.old_or_new)'>{{ portfolio.old_or_new }}</span>
          </td>
          <td class='px-1 py-1 lg:px-2 lg:py-2 text-right border-r'>{{ toNumber(portfolio.investment_price) }}</td>
          <td class='px-1 py-1 lg:px-2 lg:py-2 text-right border-r'>{{ toNumber(portfolio.investment_quantity) }}</td>
          <td class='px-1 py-1 lg:px-2 lg:py-2 text-right border-r'>{{ toNumber(portfolio.investment_value) }}</td>
          <td class='px-1 py-1 lg:px-2 lg:py-2 text-right border-r'>{{ toNumber(portfolio.market_investment_value) }}</td>
        </tr>
      </tbody>
      <tfoot v-if='showTotalRow'>
        <tr>
          <td class='font-semibold px-1 py-1 lg:px-2 lg:py-2 text-left  border-b border-l border-r' colspan='2'>합계</td>
          <td class='font-semibold px-1 py-1 lg:px-2 lg:py-2 text-right border-b border-r'>{{ toNumber(averageInvestmentValue) }}</td>
          <td class='font-semibold px-1 py-1 lg:px-2 lg:py-2 text-right border-b border-r'>{{ toNumber(sumInvestmentQuantity) }}</td>
          <td class='font-semibold px-1 py-1 lg:px-2 lg:py-2 text-right border-b border-r'>{{ toNumber(sumInvestmentValue) }}</td>
          <td class='font-semibold px-1 py-1 lg:px-2 lg:py-2 text-right border-b border-r'>{{ marketAverageValue() }}</td>
        </tr>
      </tfoot>
    </table>
    <button 
      class='mt-2 px-2 lg:px-4 py-1 lg:py-2 font-bold uppercase rounded-md border border-gray-300 hover:border-gray-500 text-xs lg:text-sm'
      @click='copyToClipboard'>
      Copy To Clipboard
    </button>
  </div>
</template>

<script>
import numbro from 'numbro'
import PortfoliosApi from '@/api/v1/portfolios'
import { mapGetters } from 'vuex'

export default {
  name: 'PortfolioSummaries',
  data () {
    return {
      selectedPortfolio: '',
      portfolios: [],
      sumInvestmentValue: 0,
      totMarketValue: 0,
      marketValue: 0,
      sumInvestmentQuantity: 0,
      averageInvestmentValue: 0,
    }
  },
  watch: {
    'drilldownInvestmentId': {
      handler: function (newId) {
        if (newId > 0) {
          this.selectInvestmentPortfolios()
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('investments', [
      'drilldownInvestmentId',
    ]),
    showTotalRow () {
      return this.portfolios.length > 1
    },
  },
  methods: {
    oldOrNewTagStyle (oldOrNew) {
      return (oldOrNew === '신주') ? 'bg-green-100 text-green-800 px-1 rounded lg:rounded-md text-xs lg:text-sm py-0 lg:py-1 border border-green-200' : 'opacity-50 bg-blue-100 text-blue-600 px-1 rounded lg:rounded-md text-xs lg:text-sm py-0 lg:py-1 border border-blue-200'
    },
    marketAverageValue () {
      if (this.marketValue <= 0 || this.totMarketValue <= 0)
        return 0
      else
        return this.toNumber(this.totMarketValue / this.marketValue)
    },
    highlightRow (portfolio) {
      return portfolio.id === this.selectedPortfolio.id ? 'bg-blue-100 font-semibold border-b' : 'border-b'
    },
    selectPortfolio (portfolio) {
      if (portfolio) {
        this.selectedPortfolio = portfolio
      }
    },
    toNumber (number) {
      if (number) {
        return numbro(parseInt(number)).format({ thousandSeparated: true })
      } else {
        return 0
      }
    },
    selectInvestmentPortfolios () {
      PortfoliosApi.getInvestmentPortfolios(this.drilldownInvestmentId).then(resp => {
        this.portfolios = resp
        this.sumInvestmentValue = 0
        this.sumInvestmentQuantity = 0
        this.totMarketValue = 0
        this.marketValue = 0
        this.portfolios.forEach(portfolio => {
          this.sumInvestmentValue += parseInt(portfolio.investment_raw_value)
          this.sumInvestmentQuantity += parseInt(portfolio.investment_quantity)
          this.totMarketValue += portfolio.market_investment_value * parseInt(portfolio.investment_value)
          if (portfolio.market_investment_value > 0)
            this.marketValue += parseInt(portfolio.investment_value)
        })
        this.sumInvestmentValue = this.sumInvestmentValue / 1000000
        this.averageInvestmentValue = this.sumInvestmentQuantity === 0 ? 0 : (this.sumInvestmentValue * 1000000 / this.sumInvestmentQuantity).toFixed(0)
      })
    },
    copyToClipboard () {
      let string = ''
      string = this.tableHeaderString ()
      this.portfolios.forEach(portfolio => {
        string += this.convertDataToString(portfolio)
      })
      string += this.tableFooterString ()
      this.$copyText(string)
    },
    tableHeaderString () {
      let string = ''
      string += '투자일자' + '\t'
      string += '자산명' + '\t'
      string += '신주/구주' + '\t'
      string += '투자단가(원)' + '\t'
      string += '투자수량(주)' + '\t'
      string += '투자원금(백만원)' + '\t'
      string += '투자밸류(백만원)' + '\t'
      string += '\n'

      return string
    },
    convertDataToString(portfolio) {
      let string = ''
      string += portfolio.investment_date + '\t'
      string += portfolio.name + '\t'
      string += portfolio.old_or_new + '\t'
      string += this.toNumber(portfolio.investment_price) + '\t'
      string += this.toNumber(portfolio.investment_quantity) + '\t'
      string += this.toNumber(portfolio.investment_value) + '\t'
      string += this.toNumber(portfolio.market_investment_value) + '\t'
      string += '\n'

      return string
    },
    tableFooterString () {
      let string = ''
      string += '합계' + '\t'
      string += '\t'
      string += '\t'
      string += this.toNumber(this.averageInvestmentValue) + '\t'
      string += this.toNumber(this.sumInvestmentQuantity) + '\t'
      string += this.toNumber(this.sumInvestmentValue) + '\t'
      string += this.marketAverageValue() + '\t'
      string += '\n'

      return string
    },
  },
}
</script>
